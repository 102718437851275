body {
  margin: 0;
  font-family: "Clash Grotesk",-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face{
  font-family: "Clash Grotesk";
  src: url("./fonts/ClashGrotesk-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Clash Grotesk Medium";
  src: url("./fonts/ClashGrotesk-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Clash Grotesk Semibold";
  src: url("./fonts/ClashGrotesk-Semibold.ttf") format("truetype");
}